<template>
  <div class="user-variables">
    <div class="user-variables__select">
      <div class="user-variables__select-item">
        <p>{{ $t('incident-type') }}</p>
        <ui-select @input="changeTypes" :options="getTypes" v-model="incidentType" />
      </div>
      <div class="user-variables__select-item">
        <p>{{ $t('subtype') }}</p>
        <ui-select @input="changeSubtype" :options="getSubtypes" v-model="subtype" />
      </div>
    </div>
    <Loader v-model="$store.state.adminStore.userVariablesLoader" />
    <Table
        v-if="(subtype.name && !getUserVariablesLoader && getSpecsTypes.length) || (newTable && !getUserVariablesLoader)"
        :title="$t('variable')"
        :descriptionList="[{title: $t('title'), width: 440}, {title: $t('type-2'), width: 150}, {title: $t('hints'), width: 128}, {title: $t('required-field'), width: 170}, {title: $t('dont-know'), width: 100}]"
        :actionText="$t('add-variable')"
        :data="getSpecsTypes"
        attention
        @addRowField="addRowField($event)"
        @deleteRowField="deleteRowField($event)"
        @changeRowField="changeRowField($event)"
        @changeType="changeType($event)"
        @addColumn="addColumn($event)"
        @updateDescription="updateDescription($event)"
        @showEditColumnNumber="showEditColumnNumber($event)"
        :sort="true"
        hideEditIcon
        showSelect
        ref="table"
        spec-group="Variable"
        :columns-count="3"
        isCheckbox
        :isGlobeIcon="true"
    />
    <template v-if="!getUserVariablesLoader && incidentType.name">
      <Table
          v-show="(subtype.name && !getUserVariablesLoader && getSpecsTypes.length && item.specGroup === 'Variable') || (item.specGroup === 'Variable' && newTable && !getUserVariablesLoader)"
          v-for="(item, key) in getSpecsTypesKey"
          :key="key"
          :item="item"
          :title="item.name"
          :columns="item.columns"
          :descriptionList="[{title: $t('title'), width: 440}]"
          :actionText="$t('new-row')"
          @addRowField="addRowFieldValues($event)"
          @deleteRowField="deleteRowFieldValues($event)"
          @changeRowField="changeRowFieldValues($event)"
          @addColumn="addColumn($event)"
          @deleteColumn="deleteColumn($event)"
          @editColumn="editColumn($event)"
          @addNewNumber="addNewNumber($event)"
          @editColumnNumber="editColumnNumber($event)"
          hideEditIcon
          :sort="true"
          newTable
          addColumns
          :columnsCount="item.columns?.length"
          is-delete
          :isGlobeIcon="true"
      />
    </template>
    <UiButton v-if="!getSpecsTypes.length && !getUserVariablesLoader && incidentType.name && subtype.name && !newTable" color="error" @click="addNewTable">
      <img src="@/assets/svg/icon-plus-white.svg" alt="">
      {{ $t('new-table') }}
    </UiButton>
    <UiNotification :message="message" v-model="showNotification" />
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect";
import Table from "@/components/ui/UiTable";
import Loader from "@/components/ui/UiLoader";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiButton from "@/components/ui/UiButton";
import UiNotification from "@/components/ui/UiNotification";

export default {
  name: "UserVariables",
  components: {UiNotification, UiButton, Loader, UiSelect, Table },

  data() {
    return {
      incidentType: { name: '', value: '' },
      subtype: { name: '', value: '' },
      loader: false,
      message: '',
      showNotification: false,
      newTable: false
    }
  },

  methods: {
    ...mapActions(
        [
          'specsTypes',
          'addVariable',
          'deleteVariable',
          'editVariable',
          'variableValues',
          'editVariableValues',
          'addVariableValues',
          'deleteVariableValues',
          'subtypes',
          'columns',
          'addColumns',
          'deleteColumns',
          'editColumns',
          'addColumnNumber'
        ]
    ),
    ...mapMutations(['setUserVariablesLoader']),

    getSpecTypes(message) {
      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
        specGroup: 'Variable'
      };
      this.specsTypes(payload).then(() => {
        this.getSpecsTypesKey.forEach(el =>
            this.columns({specTypeId: el.id}).then((res) => this.getSpecsTypesKey.forEach(val => {
                  if (val.id === res[0]?.specTypeId) {
                    val.columns = res
                  }
                })
            ))
        setTimeout(() => {
          this.setUserVariablesLoader(false)
          this.showNotification = true
          this.message = message
        }, 500);
      })
    },

    changeType(data) {
      if(data.type === 'Список') {
        let payload = {
          id: data.id,
          name: data.name,
          ord: +data.ord
        }
        this.variableValues(payload)
      }
    },

    changeTypes() {
      this.subtype = {}
      this.newTable = false
      this.subtypes(this.incidentType.id)
    },
    changeSubtype() {
      this.newTable = false
      this.setUserVariablesLoader(true)

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
        specGroup: 'Variable'
      };

      this.specsTypes(payload).then(() => {
        this.getSpecsTypesKey.forEach(el =>
            this.columns({specTypeId: el.id}).then((res) => this.getSpecsTypesKey.forEach(val => {
              if (val.id === res[0]?.specTypeId) {
                val.columns = res
              }
            })
        ))
        setTimeout(() => {
          this.setUserVariablesLoader(false)
        }, 500);
      })
    },



    addRowField(data) {
      this.$refs.table.deleteNewRowField()
      this.setUserVariablesLoader(true)
      this.addVariable({
        name: data.name,
        ord: +data.ord,
        orderTypeId: +this.incidentType.id,
        orderSubtypeId: +this.subtype.id,
        dataType: data.dataType,
        specGroup: "Variable",
        isRequired: data.isRequired,
        showUnknownMark: data.showUnknownMark
      })
      .then(() => {
        this.getSpecTypes(this.$t('variable-successfully-added'))
      })
    },

    deleteRowField(data) {
      this.setUserVariablesLoader(true)
      this.deleteVariable(data.id)
      .then(() => {
        this.getSpecTypes(this.$t('variable-successfully-deleted'))
      })
      this.getSpecTypes.length ? this.newTable = false : this.newTable = true
    },

    updateDescription(data) {
      this.setUserVariablesLoader(true)
      this.editVariable({
        dataType: data.dataType,
        name: data.name,
        ord: +data.ord,
        orderTypeId: +this.incidentType.id,
        orderSubtypeId: +this.subtype.id,
        id: data.id,
        specGroup: "Variable",
        description: data.description,
        nameInt: {
          en: data.nameIntEn
        }
      })
      .then(() => {
        this.getSpecTypes(this.$t('hint-successfully-updated'))
      })
    },

    changeRowField(data) {
      this.setUserVariablesLoader(true)
      this.editVariable({
        name: data.name,
        ord: +data.ord,
        orderTypeId: +this.incidentType.id,
        id: data.id,
        orderSubtypeId: +this.subtype.id,
        dataType: data.dataType,
        specGroup: "Variable",
        isRequired: data.isRequired,
        showUnknownMark: data.showUnknownMark,
        nameInt: {
          en: data.nameIntEn
        }
      })
      .then(() => {
        this.getSpecTypes(this.$t('variable-successfully-updated'))
      })
    },

    changeRowFieldValues(data) {
      this.setUserVariablesLoader(true)

      let payload = {
        id: this.incidentType.id,
        value: data.id,
        name: data.name,
        ord: +data.ord,
        nameIntEn: data.nameIntEn
      };

      this.editVariableValues(payload).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-updated'))
      })
    },

    addRowFieldValues (data) {
      this.setUserVariablesLoader(true)

      let payload = {
        id: data.typeId,
        name: data.name,
        ord: +data.ord,
      };

      this.addVariableValues(payload).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-added'))
      })
    },

    deleteRowFieldValues (data) {
      this.setUserVariablesLoader(true)

      let payload = {
        id: this.incidentType.id,
        value: data.id,
        name: data.name
      };

      this.deleteVariableValues(payload).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-deleted'))
      })
    },

    addNewTable () {
      if(this.incidentType) {
        this.newTable = true
        setTimeout(() => {
          this.$refs.table.addItem({id: this.incidentType.id,})
        },100)
      }
    },

    addColumn(info) {
      const data = {
        specTypeId: info.specTypeId,
        name: info.name
      }
      this.setUserVariablesLoader(true)
      this.addColumns(data).then(() => {
        this.getSpecTypes(this.$t('column-successfully-added'))
        this.columns({specTypeId: info.specTypeId})
      })
    },

    deleteColumn(item) {
      this.setUserVariablesLoader(true)
      this.deleteColumns({id: item.id}).then(() => {
        this.getSpecTypes(this.$t('column-successfully-deleted'))
        this.columns({specTypeId: item.specTypeId})
      })
    },

    editColumn(item) {
      this.setUserVariablesLoader(true)
      this.editColumns({id: item.id, name: item.editForm}).then(() => {
        // this.setUserVariablesLoader(false)
        this.getSpecTypes(this.$t('column-successfully-updated'))
      })
    },

    editColumnNumber(data) {
      this.setUserVariablesLoader(true)
      this.addColumnNumber({id: data.column.id, specValueId: data.row.id, amount: +data.amount || +data.number?.numVal}).then(() => {
        // this.setUserVariablesLoader(false)
        this.getSpecTypes(this.$t('column-successfully-updated'))
      })
    },

    addNewNumber(data) {
      this.setUserVariablesLoader(true)
      this.addColumnNumber({id: data.columnId, specValueId: data.rowId, amount: +data.number?.numVal || +data.amount}).then(() => {
        // this.setUserVariablesLoader(false)
        this.getSpecTypes(this.$t('column-successfully-updated'))
      })
    }
  },

  computed: {
    ...mapGetters(['getTypes', 'getSpecsTypes', 'getSpecsTypesKey', 'getVariablesValues', 'getSubtypes', 'getColumns', 'getUserVariablesLoader']),
  },
}
</script>

<style lang="scss" scoped>
  .user-variables {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    overflow-x: auto;
    min-height: 500px;

    button {
      max-width: 366px;
    }

    &__select {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;
      background: #FFFFFF;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 20px;
      max-width: 490px;
      width: 100%;

      ::v-deep .ui-select {
        z-index: auto;
        max-width: 305px;
      }
      ::v-deep .ui-select__label {
        display: none;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        p {
          font-size: 16px;
          line-height: 19px;
          color: #1B1A1F;
          white-space: nowrap;
        }
      }
    }
  }
</style>